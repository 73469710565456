import { type PortableTextTypeComponentProps } from "@portabletext/react";

import { ButtonLink } from "@/components/button";
import { isDefinedAndNotEmpty } from "@/lib/utils";
import { type SanityButtons } from "@/sanity/schemas/types/buttons";

export type PortableTextButtons = SanityButtons;

export type ButtonsTypeProps = {
  type: PortableTextTypeComponentProps<PortableTextButtons>;
};

export function ButtonsType({ type }: ButtonsTypeProps) {
  const { value: buttons } = type;

  if (!isDefinedAndNotEmpty(buttons.collection)) return null;

  return (
    <div className="flex flex-wrap items-center gap-2">
      {buttons.collection.map(
        ({ link, theme }, index) =>
          isDefinedAndNotEmpty(link) && <ButtonLink key={index} link={link} theme={theme} />,
      )}
    </div>
  );
}
