import {
  PortableText as PortableTextReact,
  type PortableTextComponents,
} from "@portabletext/react";
import { type VariantProps } from "cva";
import { type HTMLAttributes } from "react";

import { ButtonsType } from "@/components/portable/buttons-type";
import { ImageType } from "@/components/portable/image-type";
import { LinkMark } from "@/components/portable/link-mark";
import { cva, cx, isDefinedAndNotEmpty } from "@/lib/utils";
import { type SanityEnhancedText } from "@/sanity/schemas/types/enhanced-text";
import { type SanityFormattedText } from "@/sanity/schemas/types/formatted-text";

export type PortableTextProps = HTMLAttributes<HTMLElement> &
  VariantProps<typeof variants> & {
    value: SanityFormattedText | SanityEnhancedText;
  };

export const variants = cva({
  base: "*:my-8 first:*:mt-0 last:*:mb-0 [&_li]:mb-1 [&_ol]:list-decimal [&_ol]:pl-4 [&_p]:my-6 [&_ul]:list-disc [&_ul]:pl-4",
});

export function PortableText({ value, className, ...props }: PortableTextProps) {
  const components: PortableTextComponents = {
    block: {
      normal: (block) => <p>{block.children}</p>,
      blockquote: (block) => <blockquote>{block.children}</blockquote>,
      h1: (block) => <h2 className="font-h1">{block.children}</h2>,
      h2: (block) => <h3 className="font-h2">{block.children}</h3>,
      h3: (block) => <h4 className="font-h3">{block.children}</h4>,
    },
    marks: {
      linkMark: (mark) => <LinkMark mark={mark} />,
    },
    types: {
      accessibleImage: (type) => isDefinedAndNotEmpty(value) && <ImageType type={type} />,
      buttons: (type) => isDefinedAndNotEmpty(value) && <ButtonsType type={type} />,
    },
  };

  return (
    <div className={cx(variants(), className)} {...props}>
      <PortableTextReact value={value} components={components} />
    </div>
  );
}
