import { tailwindConfig } from "@/lib/utils";
import { type Color, colors } from "@/sanity/lib/data";

/**
 * Returns the hex value of a background colour.
 */
export function resolveBackgroundColor(color: Color["title"] | Color["value"]) {
  const isValue = (value: string): value is Color["value"] => value.startsWith("#");

  const backgroundColor = colors.find((backgroundColorObject) => {
    if (isValue(color)) return backgroundColorObject.value === color;

    return backgroundColorObject.title === color;
  });

  return backgroundColor?.value;
}

/**
 * Returns an accessible text colour hex value based on a given background colour's dark setting.
 */
export function resolveTextColor(color: Color["title"] | Color["value"]) {
  const isValue = (value: string): value is Color["value"] => value.startsWith("#");

  const backgroundColor = colors.find((backgroundColorObject) => {
    if (isValue(color)) return backgroundColorObject.value === color;
    else return backgroundColorObject.title === color;
  });

  if (backgroundColor?.dark) return tailwindConfig.theme.colors.white;

  return tailwindConfig.theme.colors.black;
}
