import { isDefinedAndNotEmpty } from "@/lib/utils";

/**
 * Returns a Date object from a given date string. Otherwise returns the current date.
 */
export function getDate(dateString?: string) {
  const currentDate = new Date();

  if (!isDefinedAndNotEmpty(dateString)) return currentDate;

  return new Date(dateString);
}

/**
 * Converts a date string into a human-readable format.
 */
export function getDatestamp(dateString: string) {
  const date = getDate(dateString);

  const day = date.toLocaleString("default", { day: "2-digit" });
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.toLocaleString("default", { year: "numeric" });

  return `${month} ${day} ${year}`;
}

/**
 * Returns a single calendar month.
 */
export function getMonth(month: number, format: Intl.DateTimeFormatOptions["month"] = "long") {
  const year = getDate().getFullYear();
  const formatter = new Intl.DateTimeFormat("en", { month: format });

  return formatter.format(new Date(year, month));
}

/**
 * Returns an array of calendar months.
 */
export function getMonths(format: Intl.DateTimeFormatOptions["month"] = "long") {
  const year = getDate().getFullYear();
  const months = [...Array(12).keys()];
  const formatter = new Intl.DateTimeFormat("en", { month: format });

  return months.map((month, index) => formatter.format(new Date(year, index)));
}

/**
 * Returns the given month as a numerical string with a leading zero.
 */
export function getMonthNumber(month: number) {
  return String(month).padStart(2, "0");
}
