import { type PortableTextTypeComponentProps } from "@portabletext/react";

import { Image } from "@/components/image";
import { isDefinedAndNotEmpty } from "@/lib/utils";
import { type SanityAccessibleImage } from "@/sanity/schemas/types/accessible-image";

export type PortableTextImage = SanityAccessibleImage;

export type ImageTypeProps = {
  type: PortableTextTypeComponentProps<PortableTextImage>;
};

export function ImageType({ type }: ImageTypeProps) {
  const { value: image } = type;

  if (!isDefinedAndNotEmpty(image)) return null;

  return <Image image={image} caption={image.caption} />;
}
