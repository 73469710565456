import { type PortableTextMarkComponentProps } from "@portabletext/react";

import { Link } from "@/components/link";
import { isDefinedAndNotEmpty } from "@/lib/utils";
import { type SanityLink } from "@/sanity/schemas/types/link";

export type PortableTextLink = SanityLink;

export type LinkMarkProps = {
  mark: PortableTextMarkComponentProps<PortableTextLink>;
};

export function LinkMark({ mark }: LinkMarkProps) {
  const { value: link, text } = mark;

  if (!isDefinedAndNotEmpty(link)) return null;

  return (
    <Link link={link} variant="inline">
      {text}
    </Link>
  );
}
