import { type VariantProps } from "cva";
import { type HTMLAttributes } from "react";

import { Container } from "@/components/container";
import { resolveBackgroundColor, resolveTextColor } from "@/lib/colors";
import { cva, cx, isDefinedAndNotEmpty, stegaClean } from "@/lib/utils";
import { type Color } from "@/sanity/lib/data";
import { type CSSPropertiesWithVariables } from "@/types";

export type SectionProps = HTMLAttributes<HTMLElement> &
  VariantProps<typeof variants> & {
    useContainer?: boolean;
    backgroundColor?: Color["title"] | Color["value"];
  };

export const variants = cva({
  variants: {
    spacing: {
      none: "",
      default:
        "py-md first:pt-0 last:pb-0 md:py-lg [&:not(section.background)+:not(section.background)]:pt-0",
    },
    useBackgroundColor: {
      true: "background bg-[--background] text-[--color]",
    },
  },
  defaultVariants: {
    spacing: "default",
    useBackgroundColor: false,
  },
});

export function Section({
  useContainer,
  backgroundColor,
  spacing,
  useBackgroundColor,
  children,
  className,
  ...props
}: SectionProps) {
  return (
    <section
      className={cx(
        variants({
          spacing,
          useBackgroundColor: isDefinedAndNotEmpty(backgroundColor) || useBackgroundColor,
        }),
        className,
      )}
      {...(isDefinedAndNotEmpty(backgroundColor) && {
        style: {
          "--background": resolveBackgroundColor(stegaClean(backgroundColor)),
          "--color": resolveTextColor(stegaClean(backgroundColor)),
        } as CSSPropertiesWithVariables,
      })}
      {...props}
    >
      {useContainer ? <Container>{children}</Container> : children}
    </section>
  );
}
