import { groq } from "next-sanity";

import { getDate } from "@/lib/dates";
import { type FilterTimePeriod } from "@/lib/forms";
import { maximumEventsOnHomepage } from "@/lib/settings";
import { isDefinedAndNotEmpty, objectEntries, objectFromEntries, objectKeys } from "@/lib/utils";
import { resolveSanityQueries } from "@/sanity/lib/utils";
import { type NextSearchParamKey, type NextSearchParams } from "@/types";

const currentYear = String(getDate().getFullYear());

const getTimePeriod = (searchParams?: NextSearchParams) => {
  const includePastEvents = searchParams && objectKeys(searchParams).includes("past-events");
  const timePeriod: FilterTimePeriod | undefined = !includePastEvents ? "future" : undefined;

  return timePeriod;
};

/* Queries */

const breadcrumbsQuery = (ids: string[]) => {
  const queries = ids.map(
    (id) => groq`
      *[_id == ${id}][0] {
        "type": _type,
        "slug": slug,
        "label": title,
        "theme": theme->
      }
    `,
  );

  return groq`[${queries.join()}]`;
};

const eventsByFilterCountQuery = (
  searchParams: NextSearchParams | undefined = {},
  name: NextSearchParamKey,
  excludeCurrentFilters = true,
) => {
  const searchParamsToCount = objectFromEntries(
    objectEntries(searchParams).filter(([key]) => !excludeCurrentFilters || key !== name),
  );

  const timePeriod = getTimePeriod(searchParams);

  return groq`count(${eventsQuery(searchParamsToCount, undefined, name, timePeriod)})`;
};

const resourcesByFilterCountQuery = (
  searchParams: NextSearchParams | undefined = {},
  name: NextSearchParamKey,
  excludeCurrentFilters = true,
) => {
  const searchParamsToCount = objectFromEntries(
    objectEntries(searchParams).filter(([key]) => !excludeCurrentFilters || key !== name),
  );

  return groq`count(${resourcesQuery(searchParamsToCount, undefined, name)})`;
};

/* Fields */

const internalLinkFieldQuery = () => groq`
  type == "internalLink" => {
    ...,
    reference->
  }
`;

const formattedTextFieldQuery = () => groq`
  ...,
  markDefs[] {
    ...,
    ${internalLinkFieldQuery()}
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const enhancedTextFieldQuery = () => groq`
  ...,
  markDefs[] {
    ...,
    ${internalLinkFieldQuery()}
  },
  _type == "buttons" => {
    ...,
    buttons[] {
      ...,
      link {
        ...,
        ${internalLinkFieldQuery()}
      }
    }
  }
`;

const navigationFieldsQuery = () => groq`
  links[] {
    ...,
    ${internalLinkFieldQuery()}
  }
`;

const actorGroupSetsFieldQuery = () => groq`
  actorGroupSets[] {
    ...,
    actorGroup->,
    actors[]->
  }
`;

const milestonesFieldQuery = (searchParams?: NextSearchParams) => {
  const topic = searchParams?.topic;
  const milestoneQuery = isDefinedAndNotEmpty(topic) ? groq`topic->slug.current == "${topic}"` : "";

  return groq`
    milestones[${milestoneQuery}] {
      ...,
      topic-> {
        _id,
        title
      },
      "theme": topic->theme-> {
        _id,
        color
      },
      "event": ^ {
        _id,
        title
      }
    }
  `;
};

/* Singletons */

export const homeQuery = (searchParams?: NextSearchParams) => {
  const modifiedSearchParams: NextSearchParams = { year: currentYear, ...searchParams };

  const timePeriod = getTimePeriod(searchParams);

  return groq`
    *[_type == "home"][0] {
      ...,
      "themes": ${themesQuery(modifiedSearchParams)},
      "topics": ${topicsQuery(modifiedSearchParams)},
      "events": ${eventsQuery(modifiedSearchParams, undefined, undefined, timePeriod)} [0...${maximumEventsOnHomepage}],
      "calendarEvents": ${eventsQuery(modifiedSearchParams, ["year"])},
      "years": ${eventYearsQuery(modifiedSearchParams)}
    }
  `;
};

export const themesSingletonQuery = () => groq`
  *[_type == "themes"][0] {
    ...,
    "themes": ${themesQuery()},
    "breadcrumbs": ${breadcrumbsQuery([`"home"`, `^._id`])}
  }
`;

export const topicsSingletonQuery = () => groq`
  *[_type == "topics"][0] {
    ...,
    "breadcrumbs": ${breadcrumbsQuery([`"home"`, `^._id`])}
  }
`;

export const roadmapQuery = (searchParams?: NextSearchParams) => {
  const modifiedSearchParams: NextSearchParams = { year: currentYear, ...searchParams };

  const timePeriod = getTimePeriod(searchParams);

  return groq`
    *[_type == "roadmap"][0] {
      ...,
      theme->,
      "themes": ${themesQuery(modifiedSearchParams)},
      "topics": ${topicsQuery(modifiedSearchParams)},
      "events": ${eventsQuery(modifiedSearchParams, undefined, undefined, timePeriod)},
      "calendarEvents": ${eventsQuery(modifiedSearchParams, ["topic", "year"])},
      "years": ${eventYearsQuery(modifiedSearchParams)},
      "breadcrumbs": ${breadcrumbsQuery([`"home"`, `^._id`])}
    }
  `;
};

export const resourcesSingletonQuery = (searchParams?: NextSearchParams) => groq`
  *[_type == "resources"][0] {
    ...,
    "themes": ${themesQuery(searchParams)},
    "topics": ${topicsQuery(searchParams)},
    "resources": ${resourcesQuery(searchParams)},
    "breadcrumbs": ${breadcrumbsQuery([`"home"`, `^._id`])}
  }
`;

/* Collections */

export const pageBySlugQuery = () => groq`
  *[_type == "page" && slug.current == $slug][0] {
    ...,
    "breadcrumbs": ${breadcrumbsQuery([`"home"`, `^._id`])}
  }
`;

export const themesQuery = (searchParams?: NextSearchParams) => groq`
  *[_type == "theme"] {
    ...,
    "topics": ${topicsByReferenceQuery()},
    "topicsCount": count(${topicsByReferenceQuery()}),
    "eventsCount": ${eventsByFilterCountQuery(searchParams, "theme")},
    "resourcesCount": ${resourcesByFilterCountQuery(searchParams, "theme")}
  } | order(order)
`;

export const themeBySlugQuery = () => groq`
  *[_type == "theme" && slug.current == $slug][0] {
    ...,
    "topics": ${topicsByReferenceQuery()},
    "breadcrumbs": ${breadcrumbsQuery([`"home"`, `"themes"`, `^._id`])}
  }
`;

export const topicsQuery = (searchParams?: NextSearchParams) => groq`
  *[_type == "topic"] {
    ...,
    theme->,
    ${actorGroupSetsFieldQuery()},
    status->,
    "statuses": ${statusesQuery()},
    "eventsCount": ${eventsByFilterCountQuery(searchParams, "topic")},
    "resourcesCount": ${resourcesByFilterCountQuery(searchParams, "topic")}
  } | order(title)
`;

export const topicBySlugQuery = (slug: string, searchParams?: NextSearchParams) => {
  const modifiedSearchParams: NextSearchParams = {
    year: currentYear,
    ...searchParams,
    theme: undefined,
    topic: slug,
  };

  const timePeriod = getTimePeriod(searchParams);

  return groq`
    *[_type == "topic" && slug.current == $slug][0] {
      ...,
      theme->,
      ${actorGroupSetsFieldQuery()},
      status->,
      "themes": ${themesQuery(modifiedSearchParams)},
      "topics": ${topicsQuery(modifiedSearchParams)},
      "events": ${eventsQuery(modifiedSearchParams, undefined, undefined, timePeriod)},
      "calendarEvents": ${eventsQuery(modifiedSearchParams, ["topic", "year"])},
      "resources": ${resourcesQuery(modifiedSearchParams)},
      "years": ${eventYearsQuery(modifiedSearchParams)},
      "statuses": ${statusesQuery()},
      "breadcrumbs": ${breadcrumbsQuery([`"home"`, `"themes"`, `^.theme._ref`, `^._id`])}
    }
  `;
};

export const topicsByReferenceQuery = () => groq`
  *[_type == "topic" && references(^._id)] {
    ...,
    theme->,
    ${actorGroupSetsFieldQuery()},
    status->,
    "statuses": ${statusesQuery()},
  } | order(title)
`;

export const eventsQuery = (
  searchParams?: NextSearchParams,
  narrowSearchParams?: NextSearchParamKey[],
  countBy?: NextSearchParamKey,
  timePeriod?: FilterTimePeriod,
) => {
  const date = new Date();
  const formattedDate = date.toISOString().split("T")[0];

  const timePeriodQueries: Partial<Record<FilterTimePeriod, string>> = {
    past: groq`&& date < "${formattedDate}"`,
    future: groq`&& date > "${formattedDate}"`,
  };

  const eventsCountQueries: Partial<Record<NextSearchParamKey, string>> = {
    theme: groq`&& count((milestones[].topic->theme->slug.current)[@ == ^.^.slug.current]) > 0`,
    topic: groq`&& count((milestones[].topic->slug.current)[@ == ^.^.slug.current]) > 0`,
    year: groq`&& string::split(date, "-")[0] == string::split(^.date, "-")[0]`,
  };

  const filtersQuery =
    (searchParams && resolveSanityQueries("event", searchParams, narrowSearchParams).filters) || "";
  const timePeriodQuery = timePeriod ? timePeriodQueries[timePeriod] : "";
  const eventsCountQuery = countBy ? eventsCountQueries[countBy] : "";

  return groq`
    *[_type == "event" ${filtersQuery} ${timePeriodQuery} ${eventsCountQuery}] {
      ...,
      ${milestonesFieldQuery(searchParams)},
      "year": string::split(date, "-")[0],
      "month": string::split(date, "-")[1],
      "isPast": dateTime(date + "T00:00:00Z") < dateTime(now()),
      } | order(date)
  `;
};

export const eventBySlugQuery = () => groq`
  *[_type == "event" && slug.current == $slug][0] {
    ...,
    ${milestonesFieldQuery()},
    "themes": ${themesQuery()},
    "breadcrumbs": ${breadcrumbsQuery([`"home"`, `"roadmap"`, `^._id`])},
    "eventMilestonesHeading": ${templatesQuery()}.eventMilestonesHeading,
    "eventMilestonesDescription": ${templatesQuery()}.eventMilestonesDescription
  }
`;

export const eventYearsQuery = (searchParams?: NextSearchParams) => groq`
  *[_type == "event"] {
    "year": string::split(date, "-")[0],
    "eventsCount": ${eventsByFilterCountQuery(searchParams, "year")}
  }
`;

/* Entries */

export const statusesQuery = () => groq`
  *[_type == "status"] {
    ...
  } | order(_createdAt)
`;

export const resourcesQuery = (
  searchParams?: NextSearchParams,
  narrowSearchParams?: NextSearchParamKey[],
  countBy?: NextSearchParamKey,
) => {
  const resourcesCountQueries: Partial<Record<NextSearchParamKey, string>> = {
    theme: groq`&& topic->theme->slug.current == ^.slug.current`,
    topic: groq`&& topic->slug.current == ^.slug.current`,
  };

  const filtersQuery =
    (searchParams && resolveSanityQueries("resource", searchParams, narrowSearchParams).filters) ||
    "";
  const resourcesCountQuery = countBy ? resourcesCountQueries[countBy] : "";

  return groq`
    *[_type == "resource" ${filtersQuery} ${resourcesCountQuery}] {
      ...,
      topic->,
      "theme": topic->theme->
    } | order(_createdAt)
  `;
};

/* Globals */

export const navigationQuery = () => groq`
  *[_type == "navigation"][0] {
    ...,
    header {
      ...,
      "themes": ${themesQuery()},
      "themesCount": count(${themesQuery()}),
      ${navigationFieldsQuery()}
    },
    footer {
      ...,
      ${navigationFieldsQuery()}
    }
  }
`;

export const templatesQuery = () => groq`
  *[_type == "templates"][0]
`;

export const settingsQuery = () => groq`
  *[_type == "settings"][0] {
    ...,
    copyright[] {
      ...,
      ${formattedTextFieldQuery()}
    }
  }
`;

export const seoQuery = () => groq`
  *[_type == "seo"][0] {
    ...
  }
`;
