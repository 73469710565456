import { type VariantProps } from "cva";
import { type HTMLAttributes } from "react";

import { cva, cx } from "@/lib/utils";

export type ContainerProps = HTMLAttributes<HTMLElement> & VariantProps<typeof variants>;

export const variants = cva({
  base: "container",
});

export function Container({ children, className, ...props }: ContainerProps) {
  return (
    <div className={cx(variants(), className)} {...props}>
      {children}
    </div>
  );
}
