import {
  type QueryParams,
  type QueryResponseInitial,
  type UseQueryOptionsDefinedInitial,
} from "@sanity/react-loader";
import * as queryStore from "@sanity/react-loader";

import {
  eventBySlugQuery,
  homeQuery,
  pageBySlugQuery,
  resourcesSingletonQuery,
  roadmapQuery,
  themeBySlugQuery,
  themesSingletonQuery,
  topicBySlugQuery,
} from "@/sanity/lib/queries";
import { type SanityEvent } from "@/sanity/schemas/documents/collections/event";
import { type SanityPage } from "@/sanity/schemas/documents/collections/page";
import { type SanityTheme } from "@/sanity/schemas/documents/collections/theme";
import { type SanityTopic } from "@/sanity/schemas/documents/collections/topic";
import { type SanityHome } from "@/sanity/schemas/documents/singletons/home";
import { type SanityResources } from "@/sanity/schemas/documents/singletons/resources";
import { type SanityRoadmap } from "@/sanity/schemas/documents/singletons/roadmap";
import { type SanityThemes } from "@/sanity/schemas/documents/singletons/themes";
import { type NextSearchParams } from "@/types";

export const useQuery = <QueryResponseResult = unknown, QueryResponseError = unknown>(
  query: string,
  params?: QueryParams,
  options?: UseQueryOptionsDefinedInitial<QueryResponseResult>,
) => {
  const snapshot = queryStore.useQuery<QueryResponseResult, QueryResponseError>(
    query,
    params,
    options,
  );

  if (snapshot.error) throw snapshot.error;

  return snapshot;
};

/* Singletons */

export function useHome(
  initial: QueryResponseInitial<SanityHome | null>,
  searchParams: NextSearchParams,
) {
  return useQuery<SanityHome | null>(homeQuery(searchParams), undefined, { initial });
}

export function useThemesSingleton(initial: QueryResponseInitial<SanityThemes | null>) {
  return useQuery<SanityThemes | null>(themesSingletonQuery(), undefined, { initial });
}

export function useRoadmap(
  initial: QueryResponseInitial<SanityRoadmap | null>,
  searchParams: NextSearchParams,
) {
  return useQuery<SanityRoadmap | null>(roadmapQuery(searchParams), undefined, { initial });
}

export function useResourcesSingleton(initial: QueryResponseInitial<SanityResources | null>) {
  return useQuery<SanityResources | null>(resourcesSingletonQuery(), undefined, { initial });
}

/* Collections */

export function usePageBySlug(initial: QueryResponseInitial<SanityPage | null>, slug: string) {
  return useQuery<SanityPage | null>(pageBySlugQuery(), { slug }, { initial });
}

export function useThemeBySlug(initial: QueryResponseInitial<SanityTheme | null>, slug: string) {
  return useQuery<SanityTheme | null>(themeBySlugQuery(), { slug }, { initial });
}

export function useTopicBySlug(
  initial: QueryResponseInitial<SanityTopic | null>,
  slug: string,
  searchParams: NextSearchParams,
) {
  return useQuery<SanityTopic | null>(topicBySlugQuery(slug, searchParams), { slug }, { initial });
}

export function useEventBySlug(initial: QueryResponseInitial<SanityEvent | null>, slug: string) {
  return useQuery<SanityEvent | null>(eventBySlugQuery(), { slug }, { initial });
}

/* Globals */
